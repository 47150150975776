@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
@import "./intro.scss";

.social-icons {
    .fa-twitter,
    .fa-twitter-square,
    .fa-linkedin,
    .fa-linkedin-square,
    .fa-github,
    .fa-github-square {
        color: $text-color;
    }
}

@import "../fontawesome/css/solid.css";
@import "../fontawesome/css/brands.css";
@import "../fontawesome/css/regular.css";
@import "../fontawesome/css/fontawesome.css";

// 3 column grid

.columns {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 10px -10px;
}

.column {
    flex: 1;
    margin: 2px;
    padding: 10px;
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
}

footer {
    padding: 0 15px;
}

.extra-wide {
    .page {
        width: 100%;
        @include breakpoint($large) {
            padding-right: 0;
        }

        @include breakpoint($x-large) {
            padding-right: 0;
        }
    }

    .page__related {
        @include breakpoint($large) {
            padding-right: 0;
        }

        @include breakpoint($x-large) {
            padding-right: 0;
        }
    }
}

@media screen and (max-width: 980px) {
    .columns .column {
        margin-bottom: 5px;
        flex-basis: 40%;
        &:nth-last-child(2) {
            margin-right: 0;
        }
        &:last-child {
            flex-basis: 100%;
            margin: 0;
        }
    }
}

@media screen and (max-width: 680px) {
    .columns .column {
        flex-basis: 100%;
        margin: 0 0 5px 0;
    }
}

// End grid

.three-offers {
    .offer {
        padding: 20px;
        padding-bottom: 3.5rem;
        background: #008cc6;
        color: #fff;
        height: 100%;
        display: block;
        text-decoration: none;
        position: relative;
        &:hover {
            text-decoration: none !important;
        }
        &.blue {
        }
        &.red {
            background: #ff6914;
        }
        &.orange {
            background: #ffa000;
        }
        i {
            font-size: 4rem;
            align-self: center;
            color: #f5f5f5;
            transform: rotateX(33deg) rotateZ(1deg);
            text-shadow:
                white 0.006em 0.006em 0.007em,
                #9c9c9c 1px 1px 1px,
                #9c9c9c 1px 2px 1px,
                #9c9c9c 1px 3px 1px,
                #9c9c9c 1px 4px 1px,
                #9c9c9c 1px 5px 1px,
                #9c9c9c 1px 6px 1px,
                #9c9c9c 1px 7px 1px,
                rgba(16, 16, 16, 0.4) 1px 18px 6px,
                rgba(16, 16, 16, 0.2) 1px 22px 10px,
                rgba(16, 16, 16, 0.2) 1px 26px 35px,
                rgba(16, 16, 16, 0.4) 1px 30px 65px,
                white -0.15em -0.1em 100px;
            &:hover {
                margin-top: -10px;
                margin-bottom: 10px;
                text-shadow:
                    white 0.006em 0.006em 0.007em,
                    #9c9c9c 1px 1px 1px,
                    #9c9c9c 1px 2px 1px,
                    #9c9c9c 1px 3px 1px,
                    #9c9c9c 1px 4px 1px,
                    #9c9c9c 1px 5px 1px,
                    #9c9c9c 1px 6px 1px,
                    #9c9c9c 1px 7px 1px,
                    rgba(16, 16, 16, 0.4) 1px 38px 26px,
                    rgba(16, 16, 16, 0.2) 1px 42px 30px,
                    rgba(16, 16, 16, 0.2) 1px 46px 65px,
                    rgba(16, 16, 16, 0.4) 1px 50px 95px,
                    white -0.15em -0.1em 100px;
            }
        }
        .align-right {
            display: inline-block;
            float: right;
            position: absolute;
            bottom: 20px;
            right: 20px;
            font-size: 3rem;
        }
        h3 {
            border-bottom: none;
            font-weight: lighter;
            font-size: 1.1rem;
            margin-top: 1em;
            margin-bottom: 1.5em;
            background: black;
            padding: 3px 20px;
        }
    }
}

@media screen and (min-width: 981px) {
    .three-offers {
        perspective: 900px;
        .column {
            transition: transform 0.5s;
            transform-style: preserve-3d;
            transform: rotateX(15deg) scale(0.9);
            perspective: 900px;
            &:hover {
                transform: rotateX(0deg) scale(1);
            }
        }
    }
}

a.site-title {
    line-height: 0.9em;
}

.skills {
    .item-skill {
        background-color: #f2b01e;

        border-radius: 4px;
        margin-bottom: 10px;
        background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0.05)
        );
        transition: 0.4s linear;
        transition-property: width, background-color;
        box-shadow:
            0 0 1px 1px rgba(0, 0, 0, 0.25),
            inset 0 1px rgba(255, 255, 255, 0.1);
        padding: 0 20px;
        white-space: nowrap;
    }
}

.timeline {
    ul {
        padding: 0;
    }
    ul li {
        list-style-type: none;
        position: relative;
        width: 6px;
        margin: 0 auto;
        padding-top: 50px;
        background: #bbb;
    }

    ul li::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: inherit;
    }

    ul li div {
        position: relative;
        bottom: 0;
        width: 300px;
        padding: 15px;
        background: #f45b69;
        time {
            display: block;
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 8px;
        }
    }

    ul li div::before {
        content: "";
        position: absolute;
        bottom: 7px;
        width: 0;
        height: 0;
        border-style: solid;
    }

    ul li:nth-child(odd) div {
        left: 45px;
    }

    ul li:nth-child(odd) div::before {
        left: -15px;
        border-width: 8px 16px 8px 0;
        border-color: transparent #f45b69 transparent transparent;
    }

    ul li:nth-child(even) div {
        left: -339px;
    }

    ul li:nth-child(even) div::before {
        right: -15px;
        border-width: 8px 0 8px 16px;
        border-color: transparent transparent transparent #f45b69;
    }

    ul li::after {
        transition: background 0.5s ease-in-out;
    }

    ul li.in-view::after {
        background: #f45b69;
    }

    ul li div {
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }

    ul li:nth-child(odd) div {
        transform: translate3d(200px, 0, 0);
    }

    ul li:nth-child(even) div {
        transform: translate3d(-200px, 0, 0);
    }

    ul li.in-view div {
        transform: none;
        visibility: visible;
        opacity: 1;
    }
}

@media screen and (max-width: 600px) {
    .timeline ul li {
        margin-left: 20px;
    }

    .timeline ul li div {
        width: calc(100vw - 91px);
    }

    .timeline ul li:nth-child(even) div {
        left: 45px;
    }

    .timeline ul li:nth-child(even) div::before {
        left: -15px;
        border-width: 8px 16px 8px 0;
        border-color: transparent #f45b69 transparent transparent;
    }
}

.masthead {
    background: #333;
    border-bottom: none;
    .masthead__inner-wrap {
        padding: 0.5em 1em;
    }
    .site-title {
        font-family: "IBM Plex Sans", sans-serif;
        font-weight: 300;
        font-size: 1.6rem;
        text-transform: uppercase;
        span {
            font-weight: 700;
            color: #ef6730;
        }
    }
}

.greedy-nav {
    background: #333;
    a {
        text-transform: uppercase;
        color: #a6a8aa;
    }
}

.greedy-nav__toggle {
    &:before {
        background-color: #000;
    }
}

.btn--primary {
    padding: 1em 2em;
}

.page__title {
    -webkit-mask-image: url(../images/noise.png);
    mask-image: url(../images/noise.png);
    -webkit-mask-size: 400px 400px;
    mask-size: 400px 400px;
    font-size: 2.1em;
    line-height: 1.2;
}

@keyframes cursor-blink {
    0% {
        opacity: 0;
    }
}

.page__title::after {
    content: "";
    width: 20px;
    height: 5px;
    background: #6f777d;
    display: inline-block;
    animation: cursor-blink 1.5s steps(2) infinite;
}

// HOME

.page__hero--overlay {
    .btn {
        background: #f9a33c;
        text-shadow: none;
        .hireme {
            margin-left: 0.5em;
        }
    }
    .page__title {
        font-size: 2.8em;
    }
    .page__lead {
        float: left;
    }
    .page__lead__right {
        color: #fff;
        float: right;
        @media screen and (max-width: 600px) {
            float: none;
            margin: 0 auto;
            text-align: center;
        }
        max-width: 468px;
    }
    .page-cta {
        clear: both;
        margin-bottom: 0;
    }
}

.page__footer {
    color: #f9f9fa;
    background: #000;
    .pre-footer {
        padding: 0.5em 20px 0.5em 20px;
        background: #253238;
        color: #fafaff;
        text-align: center;
        .btn {
            margin-bottom: 0;
            padding: 0.5em 1em;
            @media screen and (max-width: 600px) {
                display: block;
                margin: 10px;
                font-size: 1em;
            }
            i {
                font-size: 1.5em;
                display: inline-block;
                vertical-align: middle;
                color: #fff;
                margin-left: 0.5em;
            }
        }
    }
    footer {
        font-size: 0.75rem;
        h3 {
            margin: -2em 0 1em;
        }
        h4 {
            margin: 0;
        }
        h5 {
            font-size: 0.75rem;
            font-weight: normal;
            margin: 0;
        }
        .address {
            span {
                font-size: 0.75rem;
                display: block;
            }
        }
        .page__footer-follow {
            li {
                font-size: 0.75rem;
                display: block;
                padding: 0;
            }
        }
        ul.footer-menu {
            margin: 0;
            list-style: none;
            padding: 0;
        }
        .page__footer-copyright {
            font-size: 0.65rem;
        }
        @media screen and (min-width: 600px) {
            .column:last-child {
                text-align: right;
            }
        }
    }
}

// Desktop only

@media screen and (min-width: 600px) {
    .content-padding {
        padding: 0 18%;
    }
    .page__hero--overlay {
        min-height: 18em;
    }
}

// Glitch
.glitch-wrapper {
    text-align: center;
}
@mixin glitchCopy {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch {
    position: relative;
    color: #000;
    font-size: 4em;
    letter-spacing: 0.5em;
    animation: glitch-skew 1s infinite linear alternate-reverse;

    &::before {
        @include glitchCopy;
        left: 2px;
        text-shadow: -2px 0 #ff00c1;
        clip: rect(44px, 450px, 56px, 0);
        animation: glitch-anim 5s infinite linear alternate-reverse;
    }

    &::after {
        @include glitchCopy;
        left: -2px;
        text-shadow:
            -2px 0 #ff00c1,
            2px 2px #ff00c1;
        clip: rect(44px, 450px, 56px, 0);
        animation: glitch-anim2 5s infinite linear alternate-reverse;
    }
}

@keyframes glitch-anim {
    $steps: 20;
    @for $i from 0 to $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100) + px, 9999px, random(100) + px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-anim2 {
    $steps: 20;
    @for $i from 0 to $steps {
        #{percentage($i*(1/$steps))} {
            clip: rect(random(100) + px, 9999px, random(100) + px, 0);
            transform: skew((random(100) / 100) + deg);
        }
    }
}

@keyframes glitch-skew {
    $steps: 10;
    @for $i from 0 to $steps {
        #{percentage($i*(1/$steps))} {
            transform: skew((random(10) - 5) + deg);
        }
    }
}

.compare-table {
    thead .head-us {
        background: green;
        color: white;
    }
    .text-icon {
        color: green;
        font-size: 2.2em;
    }
    .fa-coins {
        color: gold;
        text-shadow: 1px 1px orange;
    }
}

.big-icon {
    font-size: 8em;
    .fa-magento {
        color: #f46f25;
    }
    .fa-shopify {
        color: #74b44c;
    }
}

.grid-container {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 600px) {
        grid-template-columns: auto;
        .grid-item {
            text-align: center;
        }
    }
    &.services-container {
        grid-gap: 0px;
        text-align: center;
        margin-bottom: 2em;
        background: #666;
        color: white;
        h2 {
            border: none;
            margin: 1em 0 0.4em;
        }
        h3 {
            margin: 1em 0 0.4em;
        }
    }
}

.inner-box {
    border: 3px solid white;
    margin: 0 50px 50px;
    padding: 40px 0;
}

.author__urls-wrapper,
.author__cando-wrapper {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    font-family: $sans-serif;
    z-index: 20;
    cursor: pointer;

    h3 {
        font-family: $sans-serif;
        font-size: $type-size-5;
        margin-top: 0;
    }

    li:last-child {
        a {
            margin-bottom: 0;
        }
    }

    .author__urls {
        span.label {
            padding-left: 5px;
        }
    }

    @include breakpoint($large) {
        display: block;
    }

    button {
        position: relative;
        margin-bottom: 0;

        &:before {
            @supports (pointer-events: none) {
                content: "";
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
            }
        }

        &.open {
            &:before {
                pointer-events: auto;
            }
        }

        @include breakpoint($large) {
            display: none;
        }
    }
}

.author__urls,
.author__cando,
.author__love {
    display: none;
    position: absolute;
    right: 0;
    margin-top: 15px;
    padding: 10px;
    list-style-type: none;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background: $background-color;
    box-shadow:
        0 2px 4px 0 rgba(#000, 0.16),
        0 2px 10px 0 rgba(#000, 0.12);
    cursor: default;

    &.is--visible {
        display: block;
    }

    @include breakpoint($large) {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        border: 0;
        background: transparent;
        box-shadow: none;
    }

    &:before {
        display: block;
        content: "";
        position: absolute;
        top: -11px;
        left: calc(50% - 10px);
        width: 0;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: $border-color transparent;
        z-index: 0;

        @include breakpoint($large) {
            display: none;
        }
    }

    &:after {
        display: block;
        content: "";
        position: absolute;
        top: -10px;
        left: calc(50% - 10px);
        width: 0;
        border-style: solid;
        border-width: 0 10px 10px;
        border-color: $background-color transparent;
        z-index: 1;

        @include breakpoint($large) {
            display: none;
        }
    }

    ul {
        padding: 10px;
        list-style-type: none;
    }

    li {
        white-space: nowrap;
    }

    a {
        display: block;
        margin-bottom: 5px;
        padding-right: 5px;
        padding-top: 2px;
        padding-bottom: 2px;
        color: inherit;
        font-size: $type-size-5;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.author__cando-wrapper,
.author__love-wrapper {
    h3 {
        display: none;
        @include breakpoint($large) {
            display: block;
        }
    }
    h3 span {
        color: #ef6730;
    }
    li {
        line-height: 1;
        margin-bottom: 0.25em;
    }
}
